import classNames from 'classnames';
import React from 'react';

import styles from './field.module.css';

export interface CheckboxWrapperProps {
  label?: string;
  note?: string | React.ReactNode;
  error?: string;
  hideError?: boolean;
  children?: React.ReactNode;
}

export const CheckboxWrapper: React.FC<CheckboxWrapperProps> = ({
  children,
  error,
  hideError = false,
  label,
  note,
}) => (
  <fieldset className={classNames(styles.UiField)}>
    {label ? (
      <legend className={classNames(styles.UiField__label)}>{label}</legend>
    ) : null}

    <div className="flex w-full flex-col gap-2">{children}</div>

    {note ? <p className={classNames(styles.UiField__note)}>{note}</p> : null}

    {!hideError && error ? (
      <p className={classNames(styles.UiField__error)}>{error}</p>
    ) : null}
  </fieldset>
);
