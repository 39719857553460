import classNames from 'classnames';
import React from 'react';

type ColumnsType = 1 | 2 | 3 | 4;

export interface GridProps {
  columns: ColumnsType;
  className?: string;
  children?: React.ReactNode;
}

const getColumnClass = (columns: ColumnsType): string => {
  switch (columns) {
    case 4:
      return '[&>*]:w-[calc(50%-10px)] md:[&>*]:w-[calc(33.33%-13.33px)] lg:[&>*]:w-[calc(25%-15px)]';
    case 3:
      return '[&>*]:w-[calc(50%-10px)] md:[&>*]:w-[calc(33.33%-13.33px)]';
    case 2:
      return '[&>*]:w-[calc(50%-10px)]';
    default:
      return '[&>*]:w-full';
  }
};

export const Grid: React.FC<GridProps> = ({ children, className, columns }) => (
  <div
    className={classNames(
      'flex flex-wrap items-start justify-center gap-5',
      getColumnClass(columns),
      className,
    )}
  >
    {children}
  </div>
);
