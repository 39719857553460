import classNames from 'classnames';

import { Image } from '../../../../components/Image';
import { AssetStoryblok } from '../../../../types/generated-storyblok';
import { Pill } from '../../Pill';

export type BannerCardProps = {
  image: AssetStoryblok;
  title: string;
  type?: string;
};

export const BannerCard: React.FC<BannerCardProps> = ({
  image,
  title,
  type,
}) => {
  return (
    <div
      className={classNames(
        'relative flex w-full flex-col overflow-hidden rounded-sm border border-divider/20',
        classNames,
      )}
    >
      <div className="w-full">
        <Image
          alt={image.alt || title}
          focus={image.focus}
          hoverEffect
          size={{
            sm: { ratio: '4/3', span: 12 },
            md: { ratio: '4/3', span: 6 },
            lg: { ratio: '4/3', span: 4 },
          }}
          src={image.filename}
        />

        <span className="absolute inset-0 bg-neutral-6/40 transition duration-300 ease-out group-hover:bg-neutral-6/40" />
      </div>

      <div className="absolute bottom-0 left-0 right-0 flex flex-col items-start gap-3 p-4 transition duration-300 ease-out group-hover:text-neutral-4 md:p-6">
        {type && <Pill label={type} />}
        <p className="text-md font-bold text-white lg:text-lg">{title}</p>
      </div>
    </div>
  );
};
