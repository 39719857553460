import classNames from 'classnames';

import { Image } from '../../../components/Image';
import styles from './avatar.module.css';

interface AvatarProps {
  label: string;
  filename?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const AvatarGroup = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex items-center justify-center -space-x-[10%]">
      {children}
    </div>
  );
};

export const Avatar = ({ filename, label, size = 'lg' }: AvatarProps) => {
  const sizeMapper = {
    xl: 'w-12 h-12',
    lg: 'w-9 h-9',
    md: 'w-6 h-6',
    sm: 'w-5 h-5',
    xs: 'w-4 h-4',
  };

  return (
    <span className={classNames(sizeMapper[size], styles.UiAvatar)}>
      {filename ? (
        <Image alt={label} src={filename} width={32} />
      ) : (
        label?.replace(/^\W+/, '')[0]?.toUpperCase()
      )}
    </span>
  );
};
