import classNames from 'classnames';
import React, { forwardRef } from 'react';

import { Image } from '../../../../components/Image';
import styles from './checkbox.module.css';
import { CheckIcon } from './CheckIcon';

interface CheckboxImageProps {
  type: 'checkbox' | 'radio';
  id: string;
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  dataTestid?: string;
  thumbnail: {
    filename: string;
    alt: string;
  };
}

export const CheckboxImage = forwardRef<HTMLInputElement, CheckboxImageProps>(
  (
    {
      checked = false,
      dataTestid,
      disabled = false,
      id,
      label,
      name,
      onChange,
      thumbnail,
      type = 'checkbox',
      value,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        className={classNames(
          checked
            ? styles.UiCheckboxImage__checked
            : styles.UiCheckboxImage__default,
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
        )}
        htmlFor={id}
        title={label}
      >
        <Image
          alt={thumbnail.alt}
          className={classNames({ 'opacity-50': checked })}
          src={thumbnail.filename}
          width={128}
        />

        <div className="absolute inset-0 flex h-full w-full items-center justify-center">
          <input
            ref={ref}
            checked={checked}
            className={classNames(styles.UiCheckboxImage__input)}
            data-testid={dataTestid}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            type={type}
            value={value}
            {...rest}
          />

          <CheckIcon
            isChecked={checked}
            isDisabled={disabled}
            size="md"
            type={type}
          />
        </div>
      </label>
    );
  },
);
