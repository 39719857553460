import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import buttonStyles from '../../Button/button.module.css';
import { Icon } from '../../Icon';
import carouselStyles from '../splide.module.css';

type Props = {
  headline?: string | null | undefined;
  items: Array<React.ReactNode>;
  gap?: 3 | 5;
  perPage?: number;
  arrowPosition?: 'top' | 'middle';
  onSlide?: (index: number) => void;
  resetTrigger?: number;
};

export const Carousel: React.FC<Props> = ({
  arrowPosition = 'top',
  gap = 5,
  headline,
  items,
  onSlide = () => {},
  perPage = 4,
  resetTrigger,
}) => {
  const mainRef = React.useRef<Splide>(null);

  useEffect(() => {
    const splideElement = mainRef.current;

    if (splideElement) {
      splideElement.go(0);
    }
  }, [items, resetTrigger]);

  if (items.length === 0) return null;

  const getPerPageForBreakpoint = (
    currentPerPage: number,
    breakpoint: number,
  ) => {
    if (breakpoint === 1024) {
      return currentPerPage > 2 ? 3 : currentPerPage;
    }
    if (breakpoint === 768) {
      return currentPerPage > 2 ? 2 : currentPerPage;
    }
    return currentPerPage;
  };

  const paginationArrows = (
    <>
      <button
        className={classNames(
          'splide__arrow splide__arrow--prev z-50 !rounded-full p-2',
          buttonStyles.UiButton__variant__tertiary,
        )}
        type="submit"
      >
        <div className="flex flex-row justify-center">
          <Icon name="ic_arrow_left" />
        </div>
      </button>
      <button
        className={classNames(
          'splide__arrow splide__arrow--next z-50 !rounded-full p-2',
          buttonStyles.UiButton__variant__tertiary,
        )}
        type="submit"
      >
        <div className="flex flex-row justify-center">
          <Icon name="ic_arrow_right" />
        </div>
      </button>
    </>
  );

  return (
    <div className={classNames(carouselStyles.UiCarousel)}>
      <Splide
        ref={mainRef}
        hasTrack={false}
        onMoved={(e) => {
          onSlide(e.index);
        }}
        options={{
          label: 'carousel',
          rewind: false,
          pagination: false,
          mediaQuery: 'min',

          arrows: false,
          focus: 0,
          gap: 12,
          perMove: 1,
          perPage: 2,

          flickPower: 300,
          flickMaxPages: 0.3,

          breakpoints: {
            768: {
              arrows: items.length > getPerPageForBreakpoint(perPage, 768),
              gap: gap * 4,
              perMove: getPerPageForBreakpoint(perPage, 768),
              perPage: getPerPageForBreakpoint(perPage, 768),
            },
            1024: {
              arrows: items.length > getPerPageForBreakpoint(perPage, 1024),
              gap: gap * 4,
              perMove: getPerPageForBreakpoint(perPage, 1024),
              perPage: getPerPageForBreakpoint(perPage, 1024),
            },
            1280: {
              arrows: items.length > getPerPageForBreakpoint(perPage, 1280),
              gap: gap * 4,
              perMove: getPerPageForBreakpoint(perPage, 1280),
              perPage: getPerPageForBreakpoint(perPage, 1280),
            },
          },
        }}
      >
        {headline && (
          <div className="mb-4 flex w-full justify-between space-x-3">
            <h2 className="relative z-20 text-md text-neutral-4 md:text-lg">
              {headline}
            </h2>

            {arrowPosition === 'top' && (
              <div
                className={classNames(
                  'splide__arrows',
                  carouselStyles.UiCarousel__arrows__top,
                )}
              >
                {paginationArrows}
              </div>
            )}
          </div>
        )}

        <div className="relative">
          {arrowPosition === 'middle' && (
            <div
              className={classNames(
                'splide__arrows',
                carouselStyles.UiCarousel__arrows__middle,
              )}
            >
              {paginationArrows}
            </div>
          )}

          <SplideTrack className={classNames(carouselStyles.UiCarousel__track)}>
            {items.map((item, index) => (
              <SplideSlide
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames(carouselStyles.UiCarousel__track__slide)}
              >
                <div className="w-full">{item}</div>
              </SplideSlide>
            ))}
          </SplideTrack>
        </div>
      </Splide>
    </div>
  );
};
